<script context="module">
	import { browser } from "$app/environment";
	import { BrowserTracing } from "@sentry/tracing";

	import * as Sentry from "@sentry/svelte";

	if (browser && import.meta.env.MODE === "production")
		// Initialize the Sentry SDK here
		Sentry.init({
			dsn: "https://7c73dd529ba04a2b8203a9d7dabad6dd@o750173.ingest.sentry.io/4503926405398528",
			integrations: [new BrowserTracing()],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		});
</script>

<script lang="ts">
	import { afterNavigate } from "$app/navigation";
	import { InsertActionVisit } from "src/graphql-operations";
	import userStore from "src/stores/user";

	afterNavigate(async (nav) => {
		if (await userStore.isAuthenticated()) {
			InsertActionVisit({
				variables: {
					user_id: (await userStore.getAuth0User()).sub,
					team_id: userStore.getSelectedProjectId(),
					at_page_slug: nav.to.url.pathname,
					previous_page_slug: nav.from?.url?.pathname ?? undefined,
				},
			});
		}
	});
</script>

<div
	class="relative h-full min-h-screen bg-[#f4f5f5] text-gray-brand-500"
	data-sveltekit-preload-data="hover"
>
	<slot />
</div>
